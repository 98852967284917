import { Box, Input, OutlinedInput, Typography } from "@mui/material";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  employeeLogin,
  loginStepOne,
  loginStepThree,
} from "../../../services/auth";
import CustomButton from "../../CustomButton";
import ModalWrapper from "../../ModalWrapper";
import VerifyAccountModal from "../VerifyAccount";
import { Style } from "../style";
import { CustomStyle } from "./style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./style.css";
import toast from "react-hot-toast";
import { MdOutlineTextsms } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { selectUser, updateUserData } from "../../../store/slice/user";
import { useDispatch, useSelector } from "react-redux";
import CustomToggle from "../../CustomToggle";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import { updateUserProfile } from "../../../services/profile";
import * as Yup from "yup";

const MobileNumberModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [openVerfiyModal, setVerfiyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [email, setEmail] = useState("");
  const [isCallOnly, setIsCallOnly] = useState(false);
  const { language } = useSelector(selectUser);
  const [select, setSelect] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required(t("profile_modal.required")),
      password: Yup.string().required(t("profile_modal.required")),
    }),
    onSubmit: async (values) => {
      // if (data) {
      setLoading(true);
      await employeeLogin({
        email: formik?.values?.email,
        password: formik?.values?.password,
      })
        .then((res) => {
          setLoading(false);
          setOpen(false);
          localStorage.setItem("token", res?.data?.token);
          localStorage.setItem("isEmployee", true);
          dispatch(
            updateUserData({
              ...res?.data?.user,
              token: res?.data?.token,
              countryId: user.country_id,
            })
          );
          toast.success("Registered!");

          formik.resetForm();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    },
  });

  const handleSubmit = async () => {
    setLoading(true);
    await loginStepOne({ email, type: "sms" })
      .then((res) => {
        setEmail("");
        setLoading(false);
        setData({ ...res.data.data, email });
        toast.success("OTP has been sent to email");
        setOpen(false);
        setVerfiyModal(true);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.data?.message);
      });
  };
  const isArabic = language === "ar";
  return (
    <Box>
      <ModalWrapper open={open} setOpen={setOpen} crossIcon>
        <Typography
          sx={{
            display: "flex",
            textAlign: "center",
          }}
        >
          <Typography
            component={"span"}
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              left: "10px",
              display: { xs: "flex", sm: "none" },
            }}
          >
            <ArrowBackIcon />
          </Typography>
          <Typography component={"span"} sx={Style.heading(isArabic)}>
            {t("profile_modal.email_address")}
          </Typography>
        </Typography>
        {/* <Typography component={"span"} sx={Style.subHeading(isArabic)}>
          {t("profile_modal.enter_email_address")}
        </Typography> */}
        {/* <CustomToggle select={select} setSelect={setSelect} /> */}
        {select ? (
          <>
            <Typography sx={CustomStyle.label(isArabic)}>
              {t("profile_modal.email_address")}
            </Typography>
            <OutlinedInput
              sx={{
                ...CustomStyle.input,
                fontFamily: "400 !important",
                fontSize: "16px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                "& .MuiOutlinedInput-input": {
                  fontWeight: 400,
                  color: "rgb(200, 200, 200)",
                },
              }}
              placeholder={t("profile_modal.enter_email_address")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              id="email"
            />
            {formik.errors.email && formik.touched.email && (
              <Typography sx={{ color: "red", width: '100%', textAlign: 'left' }}>
                {formik.errors.email}
              </Typography>
            )}

            <Typography sx={CustomStyle.label(isArabic)}>
              {t("profile_modal.password")}
            </Typography>
            <Box position={"relative"} width={"100%"}>
              <OutlinedInput
                sx={{
                  ...CustomStyle.input,
                  fontFamily: "400 !important",
                  fontSize: "16px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  "& .MuiOutlinedInput-input": {
                    fontWeight: 400,
                    color: "rgb(200, 200, 200)",
                  },
                }}
                type={showPassword ? "text" : "password"}
                placeholder={t("profile_modal.enter_password")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                id="password"
              />
              <Box
                sx={{
                  position: "absolute",
                  right: "18px",
                  top: "13px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  !!showPassword
                    ? setShowPassword(false)
                    : setShowPassword(true);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </Box>
              {formik.errors.password && formik.touched.password && (
                <Typography sx={{ color: "red", width: '100%', textAlign: 'left' }}>
                  {formik.errors.password}
                </Typography>
              )}
            </Box>
          </>
        ) : (
          <>
          <Typography sx={CustomStyle.label(isArabic)}>Email Address</Typography>
          <OutlinedInput
          placeholder="Enter Email Address"
          sx={CustomStyle.input}
          onChange={(e) => {
            setEmail(e.target.value); // Update email state directly
          }}
          value={email} // Use local state for email value
          id="email"
        />
        {!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email || "") && email && (
          <Typography sx={{ color: "red" }}>Please enter a valid email address</Typography>
        )}
                  
            {/* <PhoneInput
              country={"kw"}
              enableSearch
              disableSearchIcon
              autocompleteSearch
              enableClickOutside
              defaultErrorMessage="Invalid Number"
              inputStyle={CustomStyle.input}
              buttonStyle={CustomStyle.button}
              dropdownStyle={CustomStyle.dropDown}
              containerStyle={CustomStyle.containerStyle}
              inputProps={CustomStyle.inputProps}
              searchStyle={CustomStyle.searchStyle}
              placeholder="Mobile Number"
              value={mobile}
              countryCodeEditable={false}
              onChange={(no, data) => {
                if (data.name === "United Kingdom") setIsCallOnly(true);
                else setIsCallOnly(false);
                setEmail(no);
              }}
            /> */}
            {/* <Box sx={CustomStyle.verificationOtpContiner}>
              <MdOutlineTextsms style={CustomStyle.whatsAppIcon} />
              <Typography sx={CustomStyle.verificationOtpText(isArabic)}>
                {t("mobile_no_modal.verification_text")}{" "}
                {isCallOnly
                  ? t("mobile_no_modal.call")
                  : t("mobile_no_modal.sms")}
              </Typography>
            </Box> */}
          </>
        )}
    <CustomButton
  onClick={handleSubmit}
  buttonText={t("mobile_no_modal.continue")}
  color="secondary"
  sx={Style.button}
  loading={loading}
  disable={
    loading || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
/>
      </ModalWrapper>
      <VerifyAccountModal
        open={openVerfiyModal}
        setOpen={setVerfiyModal}
        data={data}
        setData={setData}
      />
    </Box>
  );
};

export default MobileNumberModal;
