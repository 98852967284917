import { COLORS } from "../../../theme";

export const CustomStyle = {
  input: {
    width: "100%",
    margin: 0,
    fontWeight: "bold",
    color: "#fff",
    borderRadius: "8px",
    height: "52px",
    fontSize: "16px",
    lineHeight: "18px",
    background: "#1E2324",
  },
  label: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 600,
    fontSize: "12px",
    color: COLORS.white,
    opacity: "0.6",
    width: "100%",
    display: "flex",
    alignItems: "start",
  }),
  buttons: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    background: "#1E2324",
  },
  dropDown: {
    width: "calc(100% - 65px)",
    background: "#1E2324",
    position: "fixed",
    textAlign: "start",
    color: COLORS.white,
    paddingTop: "20px",
    boxSizing: "border-box",
    overflowY: "auto",
  },
  containerStyle: {
    overflow: "hidden",
    boxSizing: "border-box",
  },
  searchStyle: {
    boxSizing: "border-box",
    width: "100%",
    height: "40px",
    padding: "0 9px",
    position: "absolute",
    top: -24,
    left: -6,
    background: "aqua !important",
  },
  inputProps: {
    name: "phone",
    required: true,
    autoFocus: true,
  },
  verificationOtpContiner: {
    background: "#1E2324",
    display: "flex",
    padding: 1,
    gap: "10px",
    width: 'calc(100% - 16px)',
    alignItems: "center",
  },
  verificationOtpText: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    opacity: "0.7",
    fontSize: "12px",
    width: "100% !important",
    textAlign: "left",
  }),
  whatsAppIcon: { width: "32px", height: "32px" },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    gap: "10px",
  },
  label: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontWeight: 600,
    fontSize: "12px",
    color: COLORS.white,
    opacity: "0.6",
    width: "100%",
    display: "flex",
    alignItems: "start",
  }),
  inputm: {
    width: "100%",
    margin: 0,
    fontWeight: "bold",
    color: "#fff",
    borderRadius: "8px",
    height: "52px",
    fontSize: "16px",
    lineHeight: "18px",
    background: "#1E2324",
  },
  input: {
    color: "rgb(200, 200, 200)",
    fontSize: { xs: "14px", sm: "16px" },
    background: "#1E2324",
    width: "100%",
    borderRadius: "12px",
  },
  lists: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "12px",
    boxSizing: "border-box",
    padding: "10px 15px",
    mt: 0,
  },
  listItemsContainer: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "space-between",
  },
  listItems: {
    gap: "10px",
    width: "100%",
    display: "flex !important",
    alignItems: "center",
    height: "fit-content",
    verticalAlign: "center",
  },
  customIcons: {
    fontSize: "24px",
    width: "24px",
    height: "24px",
    borderRadius: 20,
  },
  button: (isArabic) => ({
    width: "100%",
    height: "60px",
    flexShrink: "0",
    borderRadius: "16px",
    background: "var(--Primary, #FFBA83)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    color: "var(--Dark, #060B0C)",
    textAlign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    position: { xs: "absolute", sm: "static" },
    width: { xs: "calc(100% - 40px)", sm: "100%" },
    bottom: "20px",
    left: "20px",
    mt: "25px",
  }),
  heading: (isArabic) => ({
    fontSize: "16px",
    fontWeight: "800",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    color: "#fff",
  }),
  subHeading: (isArabic) => ({
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontStyle: "normal",
    color: COLORS.grey,
  }),
};
